import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiDialog: {
      paperFullWidth: {
        padding: "15px",
      },
    },

    MuiPaper: {
      elevation2: {
        boxShadow:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        background:
          "linear-gradient(91.2deg, rgb(228, 239, 255) 2.58%, rgba(228, 239, 255, 0) 103.63%)",
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 400,
        fontSize: 14,
        fontFamily: "'Poppins', sans-serif",
      },

      colorTextSecondary: {
        color: "#536175",
      },
    },
    MuiAppBar: {
      colorDefault: {
        color: "#fafafa",
        backgroundColor: "rgb(7, 22, 45)",
      },
    },

    MuiListSubheader: {
      root: {
        color: "#FFF",
        fontSize: "0.875rem",
        boxSizing: "border-box",
        listStyle: "none",
        fontWeight: "400",
        lineHeight: "48px",
      },
    },

    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiButton: {
      containedSecondary: {
        color: "rgb(16 38 72)",
        border: "2px solid #102648",
        backgroundColor: "#fff",
      },
      root: {
        color: "#52565c",
        padding: "6px 16px",
        fontSize: "14px",
        minWidth: "64px",
        boxSizing: "border-box",
        transition:
          "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontfamily: "'Poppins', sans-serif",
        fontWeight: "400",
        lineHeight: "1.75",
        borderRadius: "4px",
      },

      contained: {
        boxShadow: "none",
        borderRadius: 27,
        backgroundColor: "#fff",
        "&:hover": {
          boxShadow: "none",
        },
      },
      outlined: {
        boxShadow: "none",
        borderRadius: 27,
        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 27,
        boxShadow: "none",
      },
      containedSizeLarge: {
        color: "#ffffff",
        padding: "14px 50px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "rgb(16, 38, 72)",
      dark: "#202831",
      light: "#e2e3fa",
    },
    secondary: {
      main: "rgb(16 38 72 / 63%)",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
