import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 80,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: theme.palette.primary.paper,
  },
  content: {
    flex: "1 1 auto",
    minHeight: "120vh",
    maxHeight: "auto",
    overflow: "hidden",
    position: "relative",
    WebkitOverflowScrolling: "touch",
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.root}>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content} id="main-scroll">
                {children}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Box>
          <Typography
            style={{
              justifyContent: "center",
              fontSize: "12px",
              fontWeight: "normal",
              color: "black",
              display: "flex",
            }}
          >
            Copyright © 2022 . All Rights Reserved.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
