const url = "https://node-defi.mobiloitte.com";
const Apiconfig = {
  adminLogin: `${url}/api/v1/admin/adminLogin`,
  getAdminProfile: `${url}/api/v1/admin/getAdminProfile`,
  dashboard: `${url}/api/v1/admin/dashboard`,
  getAlluser: `${url}/api/v1/admin/getAlluser`,
  blockUnblockUser: `${url}/api/v1/admin/blockUnblockUser`,
  viewUser: `${url}/api/v1/admin/viewUser`,
  listNFT: `${url}/api/v1/admin/listNFT`,
  placedOrderList: `${url}/api/v1/admin/placedOrderList`,
  cancelAlreadyPlaceOrderList: `${url}/api/v1/admin/cancelAlreadyPlaceOrderList`,
  viewParticularNFT: `${url}/api/v1/admin/viewParticularNFT`,
  cancelAlreadyPlaceOrder: `${url}/api/v1/admin/cancelAlreadyPlaceOrder`,
  viewTransaction: `${url}/api/v1/admin/viewTransaction`,
  viewNftTransaction: `${url}/api/v1/admin/viewNftTransaction`,
  viewPlaceOrder: `${url}/api/v1/admin/viewPlaceOrder`,
  viewCancelOrder: `${url}/api/v1/admin/viewCancelOrder`,
  forgetPassword: `${url}/api/v1/admin/forgetPassword`,
  verifyOtp: `${url}/api/v1/admin/verifyOtp`,
  resetPassword: `${url}/api/v1/admin/resetPassword`,
  resendOTP: `${url}/api/v1/admin/resendOTP`,
  listCategory: `${url}/api/v1/admin/listCategory`,
  deleteCategory: `${url}/api/v1/admin/deleteCategory`,
  viewCategory: `${url}/api/v1/admin/viewCategory`,
  editCategory: `${url}/api/v1/admin/editCategory`,
  addCategory: `${url}/api/v1/admin/addCategory`,
};

export default Apiconfig;
