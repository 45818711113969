import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    margin: "-8px",
    display: "flex",
    justifyContent: "center",
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.content}>
      <Grid item xs={12} sm={12} md={4}>
        {children}
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Box>
          <Typography
            style={{
              justifyContent: "center",
              fontSize: "12px",
              fontWeight: "normal",
              color: "black",
              display: "flex",
            }}
          >
            Copyright © 2022 . All Rights Reserved.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node,
};

export default LoginLayout;
