import React, { useEffect } from "react";
import { useLocation, matchPath, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  Slide,
  Dialog,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { BiTransfer, BiCategory } from "react-icons/bi";
import { TiChartLine } from "react-icons/ti";
import { DiCodepen } from "react-icons/di";
import { ImHome } from "react-icons/im";
import { MdBugReport } from "react-icons/md";
import { GiStaticWaves } from "react-icons/gi";
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import NavItem from "./NavItem";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: ImHome,
        href: "/dashboard",
      },
    ],
  },
  {
    items: [
      {
        title: "User Management",
        icon: FaUser,
        href: "",
        items: [
          {
            title: "User List",
            icon: "",
            href: "/User-List",
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: "Categories",
        icon: BiCategory,
        href: "/categories",
      },
    ],
  },

  {
    items: [
      {
        title: "NFT Management",
        icon: DiCodepen,
        href: "",
        items: [
          {
            title: "NFT List",
            icon: "",
            href: "/NFT-LIST",
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: "Transactions",
        icon: BiTransfer,
        href: "/transaction",
      },
    ],
  },
  {
    items: [
      {
        title: "NFT Report Management",
        icon: MdBugReport,
        href: "/nft-report-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Commission Management",
        icon: TiChartLine,
        href: "/commision-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Static Content Management",
        icon: GiStaticWaves,
        href: "/Termsof-Use",
      },
    ],
  },
];

function renderNavItems({ items, ...props }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...props }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  isNested = false,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          isNested: true,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={
          <div style={{ width: isNested ? "auto" : 100, textAlign: "left" }}>
            {item.title}
          </div>
        }
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 0,

    background: "rgb(7 22 45)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  lastbtn: {
    color: "#969ba1",
    "&:hover": {
      color: "#fff",
    },
  },
  butm: {
    display: "flex",
    justifyContent: "center",

    paddingBottom: "30px",
  },
  butm1: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  butm2: {
    backgroundColor: "#252d47",
    color: "#fff",
    "&:hover": {
      backgroundColor: "red",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          <Typography
            variant="h3"
            style={{
              color: "#fff",
              paddingLeft: "15px",
              paddingBottom: "8px",
              paddingTop: "12px",
            }}
          >
            Loram
          </Typography>
          {sections.map((section, i) => (
            <List
              style={{ paddingBottom: "0px" }}
              key={`menu${i}`}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{
                    paddingLeft: "15px",
                    fontSize: "13px",
                    fontWeight: "300",
                  }}
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <Box
            style={{
              paddingLeft: "17px",
              fontSize: "13px",
              fontWeight: "300",
              alignItems: "center",
            }}
          >
            <Button
              className={classes.lastbtn}
              startIcon={<FaSignOutAlt />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
      <Dialog
        open={open}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            style={{ color: "#52565c", fontSize: "20px" }}
          >
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.butm}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm1}
            to="/"
            component={Link}
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.butm2}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
